@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --highlight: #4daa5c;
  --description: #5b5b5b;
  --header: #000;
  --noti: #ff0000;
  --disable: #e6ecee;
}

body {
  font-family: "Inter", sans-serif !important;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* input[name="gtttNumber"] {
  pointer-events: none;
}

input[name="name"] {
  pointer-events: none;
}

input[name="dateOfBirth"] {
  pointer-events: none;
} */

.camera-area {
  margin: 0 0 50px; 
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*==================================
Step Bar CSS
====================================*/
.stepbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px auto 0;
}

.stepbar .step-button-group {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}

.stepbar .step-button-group .active {
  border: 1px solid var(--highlight);
  border-radius: 50px;
}

.stepbar .step-button-group .step-button {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepbar .step-button-group .step-button .sub-step-button {
  width: 18px;
  height: 18px;
  background-color: var(--disable);
  border-radius: 50px;
}

.stepbar .step-button-group .step-button .sub-active {
  background-color: var(--highlight) !important;
}

.stepbar .step-button-group .progress-connect {
  height: 0px;
  width: 100px;
  border: 1px solid var(--disable);
  margin: 0 10px 0;
}

.stepbar .step-button-group .connect-active {
  height: 0px;
  width: 100px;
  border: 1px solid var(--highlight);
  margin: 0 10px 0;
}

.devices-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 500;
}

.devices-list label {
  margin-top: 5px;
  margin-right: 10px;
}

.devices-list select {
  width: 200px;
}

/*==================================
Front Card Phase CSS
====================================*/
.front-card-snap-phase {
  padding: 50px 0;
}

.front-card-snap-phase .container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.front-card-snap-phase .container .phase-title {
  text-align: center;
  min-width: 500px;
}

.front-card-snap-phase .container .phase-title .step-description {
  color: var(--header);
  font-weight: bold;
  font-size: 24px;
}

.front-card-snap-phase .container .phase-title .step-description .step {
  color: var(--highlight);
}

.front-card-snap-phase .container .phase-title .user-step-noti {
  color: var(--noti);
  font-size: 24px;
  font-weight: 400;
}

.front-card-snap-phase .container .data-field {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.front-card-snap-phase .container .data-field .input-field input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #b7bec1;
  padding: 4px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}

.front-card-snap-phase .container .data-field .button-field {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.front-card-snap-phase .container .video-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 50px auto;
}

.front-card-snap-phase .container .video-field img {
  z-index: 1;
}

.front-card-snap-phase .container .video-field .camera-button {
  width: 50px !important;
  height: 50px !important;
  margin-top: 28px;
  background-color: #ff6565;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0 2px 10px 0 #000;
  border: none;
}

.front-card-snap-phase .container .data-field .input-label {
  color: var(--description);
  font-size: 24px;
  font-weight: 500;
}

#card-overlayer {
  position: absolute;
  bottom: 78px;
}



/* .canvas-frame {
  margin: 0 auto 30px;
  width: 450px;
} */

#canvas1 {
  height: auto !important;
  width: 450px;
  margin-bottom: 30px;
}

.processing {
  width: 50px;
  height: 50px;
  background-color: grey;
  box-shadow: 0 2px 10px 0 #000;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
}

.loading-animate {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*==================================
Button CSS
====================================*/
.snap-again-button {
  background-color: #fff;
  color: var(--highlight);
  font-size: 20px;
  font-weight: 500;
  border: 1px solid var(--highlight);
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

.continue-step-button {
  background-color: var(--highlight);
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #928b8b;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

/*==================================
Face Phase CSS
====================================*/
.front-card-snap-phase .container .video-field .camera-button-disable {
  width: 50px !important;
  height: 50px !important;
  margin-top: 28px;
  background-color: gray;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0 2px 10px 0 #000;
  border: none;
}

.facevideo-stepbar {
  bottom: 90px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.facevideo-stepbar .face-center,
.face-left,
.face-right {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: gray;
  font-weight: 600;
  border-radius: 50px;
  cursor: default;
}

.facevideo-stepbar .face-center-done,
.face-left-done,
.face-right-done {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  background-color: var(--highlight);
  color: #fff;
  border-radius: 50px;
}

.facevideo-stepbar .face-center-fail,
.face-left-fail,
.face-right-fail {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff6565;
  font-weight: 600;
  color: #fff;
  border-radius: 50px;
}

.facevideo-stepbar .connect {
  width: 50px;
  height: 10px;
  background-color: #fff;
  z-index: 0;
  border-radius: 5px;
  margin: 10px;
}

.facevideo-stepbar .connect .sub-connect {
  z-index: 1;
  height: 10px;
  width: 50px;
  border-radius: 5px;
  background-color: #4daa5c;
  animation: processing 3s;
}

@keyframes processing {
  from {
    width: 0px;
  }
  to {
    width: 50px;
  }
}

.front-card-snap-phase .container .face-video video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

#videoElement1{
  width: auto;
  height: auto;
}
#face {
  transform: scaleX(-1);
  display: none;
}

#canvas2 {
  transform: scaleX(-1);
   display: none;
}


#output {
  position: absolute;
  top: 0;
}

.blaze-message, .order-message {
  position: absolute;
  z-index: 2;
  bottom: 100px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.facemodel-message {
  position: absolute;
  z-index: 2;
  bottom: 70px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

/*==================================
Confirm Phase CSS
====================================*/
#confirm-form {
  margin: 50px 0;
  padding-bottom: 50px;
}

#confirm-form .address-text {
  color: var(--highlight);
  font-size: 24px;
  font-weight: 500;
  padding: 20px 15px;
}

