:root {
  --highlight: #4daa5c;
  --description: #5b5b5b;
  --header: #000;
  --noti: #ff0000;
  --disable: #e6ecee;
}

@media only screen and (max-width: 767px) {
  .front-card-snap-phase .container .video-field video {
    height: 240px !important;
    /* width: 240px !important; */
  }

  .front-card-snap-phase .container .face-video video {
    height: 320px !important;
    width: 320px !important;
  }

  .stepbar .step-button-group .progress-connect {
    height: 0px;
    width: 50px;
    border: 1px solid var(--disable);
    margin: 0 10px 0;
  }

  .stepbar .step-button-group .connect-active {
    height: 0px;
    width: 50px;
    border: 1px solid var(--highlight);
    margin: 0 10px 0;
  }

  .stepbar .step-button-group .step-button {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stepbar .step-button-group .step-button .sub-active {
    background-color: var(--highlight);
    width: 25px;
  }

  .stepbar .step-button-group .step-button .sub-step-button {
    width: 13px;
    height: 13px;
    background-color: var(--disable);
    border-radius: 50px;
  }

  .devices-list {
    display: flex;
    flex-direction: column;
    margin: 50px auto;
  }

  .front-card-snap-phase .container .phase-title {
    min-width: 300px;
  }

  .front-card-snap-phase .container .phase-title .step-description {
    color: var(--header);
    font-weight: bold;
    font-size: 18px;
  }

  .front-card-snap-phase .container .phase-title .user-step-noti {
    color: var(--noti);
    font-size: 18px;
    font-weight: 400;
  }

  #canvas1 {
    width: auto;
  }

  .blaze-message,
  .order-message {
    bottom: 75px;
    font-size: 18px;
  }

  #output {
    width: 320px !important;
    height: 240px !important;
  }

  .facevideo-stepbar {
    bottom: 372px !important;
  }

  .blaze-message,
  .order-message {
    bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1550px) {
}

@media only screen and (min-width: 1350px) {
  .container {
    max-width: 1250px;
  }
}
